.sphere-input-label.sphere-input-label {
  display: block;
  font-weight: 500;
}

.sphere-input-label--with-background {
  background-color: #f5f7f9;
  padding: 0.5rem 0.75rem;
  border-radius: 0.625rem 0.5rem;
}

.sphere-input-label__required {
  color: #f85858;
  font-size: smaller;
}
